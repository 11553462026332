import { handleResponse, API, getToken } from './utils';

const report = {
  getCardUpdateReport: async () => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.get('/v1/report/card-update-report', {
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  getFuturePlansReport: async () => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.get('/v1/report/future-plans-report', {
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  getDroppedPatientsReport: async (data) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.post('/v1/report/get-dropped-patients-report', data, {
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  getProtectionPlanBatteryReport: async (data) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.post('/v1/report/get-protection-plan-battery-report', data, {
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  getInvalidBatteryReport: async (data) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.post('/v1/report/get-invalid-battery-report', data, {
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  getActiveHsppPatientReport: async (data) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.post('/v1/report/get-active-hspp-patient-report', data, {
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  getActiveHsppPatientReportBasedOnPLanName: async (data) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.post(
        '/v1/report/get-active-hspp-patient-report-based-on-plan-name',
        data,
        {
          headers: { Authorization: 'Bearer: ' + token }
        }
      );
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  getActiveHsppPatientHistoryReport: async (data) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.post('/v1/report/get-active-hspp-patient-history-report', data, {
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  getActiveHsppStateLocationReport: async (data) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.post('/v1/report/get-active-hspp-state-location-report', data, {
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  getAmplifonPatientReport: async () => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.get('/v1/report/get-amplifon-patient-report', {
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  SyncAmplifonPatientReport: async () => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.get('/v1/report/sync-amplifon-patient-report', {
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  getChargebackReport: async (data) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.post('/v1/report/get-chargeback-report', data, {
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  sendChargeBackEmail: async (chargeback_id) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.post(
        '/v1/report/send-chargeback-email',
        { chargeback_id },
        {
          headers: { Authorization: 'Bearer: ' + token }
        }
      );
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  editInvalidBatteryReport: async (data) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.post(
        '/v1/report/edit-invalid-battery-report',
        { data: data },
        {
          headers: { Authorization: 'Bearer: ' + token }
        }
      );
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  getNewsLetterReport: async (data) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.post('/v1/report/get-newsletter-report', data, {
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (error) {
      response = error;
    }
    return handleResponse(response);
  },
  sendNewsLetter: async (email_data) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.post(
        '/v1/report/send-monthly-newsletter-mail',
        { email_data: email_data },
        {
          headers: { Authorization: 'Bearer: ' + token }
        }
      );
    } catch (error) {
      console.log(error);
      response = error;
    }
    return handleResponse(response);
  },
  getLossAndDamageReport: async (plan_code) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.get(
        `/v1/report/get-subscription-loss-and-damage-report?plan_code=${plan_code}`,
        {
          headers: { Authorization: 'Bearer: ' + token }
        }
      );
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  getHsppAttatchmentRateReport: async (data) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.post('/v1/report/get-hspp-attatchment-rate-report', data, {
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },

  getSubscriptionPlanChangeReport: async (data) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.get(`/v1/report/get-subscription-plan-change-report?search_by=${data}`, {
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  }
};

export default report;
