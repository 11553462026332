import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Card, Button, Input, NewButton, Checkbox, AgGridTable } from 'components';
import { PermissionWrapper, HasPermission } from 'components';
import { Tooltip, OverlayTrigger, ProgressBar } from 'react-bootstrap';
import { MdDelete } from 'react-icons/md';
import { FcShipped, FcInfo } from 'react-icons/fc';
import { FaCheckCircle, FaTimes } from 'react-icons/fa';
import { TbFileInvoice } from 'react-icons/tb';
import { IoMdTime } from 'react-icons/io';
import { BiEditAlt } from 'react-icons/bi';
import moment from 'moment';
import {
  parseHsppId,
  renderStatusColorClass,
  renderInvoiceStatusColorClass
} from 'services/helpers';
import { HiOutlineReceiptRefund } from 'react-icons/hi2';
import { LiaCcPaypal } from 'react-icons/lia';
import Spinner from 'react-bootstrap/Spinner';
import { GrNotes } from 'react-icons/gr';

import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFDownloadLink,
  Image
} from '@react-pdf/renderer';
import logo from 'assets/images/logo copy.png';

const Invoice = ({ _this }) => {
  const customer = _this.customerDetails;

  const tooltipUI = (row) => {
    if (row.data.status === 'Failed') {
      return (
        <div>
          {JSON.parse(row.data?.payment_gateway_response)?.last_payment_error?.message ||
            JSON.parse(row.data?.payment_gateway_response)?.message ||
            JSON.parse(row.data?.payment_gateway_response)?.failure_message}
        </div>
      );
    } else if (row.data.status === 'Paid' || row.data.status === 'Refunded') {
      const info = row.data?.refunded_data?.map((item, i) => {
        return (
          <div key={i}>
            <p>
              {moment(item.refunded_on).format('YYYY-MM-DD')} | ${item.amount} | {item.status}
            </p>
            <p>--{item.reason ? item.reason : ''}--</p>
          </div>
        );
      });
      return info;
    }

    return null;
  };

  const invoice_table_columns = [
    {
      field: 'status',
      headerName: 'Status',
      width: 120,
      cellRenderer: (row) => (
        <OverlayTrigger
          placement="top"
          overlay={
            row.data.status === 'Failed' ||
            row.data.status === 'Refunded' ||
            row.data.status === 'Paid' ? (
              <Tooltip id="tooltip-bottom ">{tooltipUI(row)}</Tooltip>
            ) : (
              <></>
            )
          }
        >
          {({ ref, ...triggerHandler }) => (
            <div
              {...triggerHandler}
              ref={ref}
              className={`font-semibold px-2 py-1 rounded-full text-xs 
                ${renderInvoiceStatusColorClass(
                  row.data.status === 'Paid' || row.data.status === 'ChargebackWon'
                    ? 'Paid'
                    : row.data.status === 'Refunded'
                    ? 'Refunded'
                    : row.data.status === 'Processing'
                    ? 'Processing'
                    : 'Failed'
                )}
              `}
            >
              {row.data.status === 'Paid' ? (
                'PAID'
              ) : row.data.status === 'Failed' ? (
                'FAILED'
              ) : row.data.status === 'Void' ? (
                'CANCELLED'
              ) : row.data.status === 'Cancel' ? (
                'CANCELLED'
              ) : row.data.status === 'Processing' ? (
                'PROCESSING'
              ) : row.data.status === 'Refunded' ? (
                'REFUNDED'
              ) : row.data.status === 'Chargeback' ? (
                <div className="flex items-center justify-between">
                  <p>CHARGEBACK</p>
                  <IoMdTime size={18} />
                </div>
              ) : row.data.status === 'ChargebackWon' ? (
                <div className="flex items-center justify-between">
                  <p>CHARGEBACK</p>
                  <FaCheckCircle size={18} />
                </div>
              ) : row.data.status === 'ChargebackLost' ? (
                <div className="flex items-center justify-between">
                  <p>CHARGEBACK</p>
                  <FaTimes size={18} />
                </div>
              ) : (
                'PENDING'
              )}
            </div>
          )}
        </OverlayTrigger>
      )
    },

    {
      field: 'due_date',
      headerName: 'Due Date',
      width: 120,
      cellRenderer: (row) => (
        <div className="flex gap-2 justify-center">
          <span>{row.data.due_date && moment(row.data.due_date).format('YYYY-MM-DD')}</span>
          <PermissionWrapper permission={'edit_due_date'}>
            {row.data.status == 'Pending' && (
              <span>
                <BiEditAlt
                  size={20}
                  className="cursor-pointer"
                  onClick={() => {
                    _this.handleDueDateEdit(row.data._id, row.data.due_date);
                  }}
                />
              </span>
            )}
          </PermissionWrapper>
        </div>
      )
    },
    {
      headerName: 'Payment Method',
      width: 120,
      cellRenderer: (row) =>
        row.data.payment_gateway_transaction_id === 'credit' ? (
          <div>Credits</div>
        ) : (
          row.data?.payment_details?.payment_method && (
            <div>
              {row.data?.payment_details?.payment_method == 'check'
                ? 'Check : **' + row.data?.payment_details?.ach_details?.account_last_4_digit
                : 'Card : **' + row.data?.payment_details?.card_details?.card_last_4_digit}
            </div>
          )
        )
    },
    {
      field: 'amount',
      headerName: 'Amount',
      width: 120,
      cellRenderer: (row) => <div className="font-bold">${row.data.amount}</div>
    },
    {
      headerName: 'Transaction ID',
      field: 'payment_gateway_transaction_id',
      minWidth: 260,

      cellRenderer: (row) =>
        row.data.payment_gateway_transaction_id && (
          <div className="text-sm text-body-color">{row.data.payment_gateway_transaction_id}</div>
        )
    },

    {
      field: 'paid_on',
      headerName: 'Payment Process Date',
      width: 120,
      cellRenderer: (row) => (
        <div className="flex gap-2 justify-center">
          <span>
            {row.data.status === 'Refunded'
              ? moment(row?.data?.refunded_data[0]?.refunded_on).format('YYYY-MM-DD')
              : row.data.paid_on && moment(row.data.paid_on).format('YYYY-MM-DD')}
          </span>
        </div>
      )
    },

    {
      cellRenderer: (row) => (
        <center>
          {row.data.status === 'Paid' ||
          row.data.status === 'Processing' ||
          row.data.status === 'ChargebackWon' ? (
            <PermissionWrapper permission="refund_invoices">
              <button
                className="bg-transparent border-rose-800 border-[1px] text-rose-800 px-2 py-1 rounded text-xs flex flex-row gap-2 self-center w-20 justify-center items-center"
                onClick={() => _this.handleRefundDetails(row.data)}
              >
                <HiOutlineReceiptRefund size={14} /> Refund
              </button>
            </PermissionWrapper>
          ) : (
            row.data.status === 'Failed' && (
              <PermissionWrapper permission="pay_failed_invoices">
                <button
                  className="bg-transparent border-blue-500 border-[1px] text-blue-500 px-2 py-1 rounded text-xs flex flex-row gap-2 self-center w-20 justify-center items-center"
                  onClick={() => _this.handleRetryPayment(row.data._id)}
                >
                  <LiaCcPaypal size={14} /> Pay
                </button>
              </PermissionWrapper>
            )
          )}
        </center>
      ),
      headerName: 'Refund/Pay',
      width: 150
    },
    {
      headerName: 'Actions',
      width: 150,
      cellRenderer: (row) => (
        <div className="py-2 flex gap-2 px-2 justify-center items-center">
          <div className="flex justify-center items-center">
            {HasPermission({ permission: ['delete_invoices'], require_all: false }) ? (
              <button
                className="text-red-500"
                onClick={() => _this.handleDeleteInvoices(row.data._id)}
              >
                <MdDelete size={18} />
              </button>
            ) : null}
          </div>

          {row?.data?.payment_gateway_response &&
            JSON.parse(row.data.payment_gateway_response)?.outcome?.seller_message && (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="tooltip-bottom ">
                    <p>{JSON.parse(row.data.payment_gateway_response)?.outcome?.seller_message}</p>
                    <p></p>
                  </Tooltip>
                }
              >
                <span className="cursor-pointer">
                  <FcInfo size={18} />
                </span>
              </OverlayTrigger>
            )}
          {row.data.is_migrated_from_fusebill == true && (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="tooltip-bottom ">
                  <p>--Migrated--</p>
                  <p>Fusebill ID: {row.data.fusebill_id}</p>
                </Tooltip>
              }
            >
              <span className="cursor-pointer">
                <FcShipped size={18} />
              </span>
            </OverlayTrigger>
          )}
          <p>
            <Link
              to={`/view-invoice/${row.data._id}`}
              target="_blank"
              className="text-blue-500 underline"
            >
              {/* #{parseHsppId(row.data._id)} */}
              <TbFileInvoice size={18} />
            </Link>
          </p>
        </div>
      )
    }
  ];

  if (HasPermission({ permission: ['refund_duplicate_invoices'], require_all: false }))
    invoice_table_columns.push({
      cellRenderer: (row) => (
        <center>
          <button
            className="bg-transparent border-rose-800 border-[1px] text-rose-800 px-2 py-1 rounded text-xs flex flex-row gap-2 self-center justify-center items-center"
            onClick={() => _this.handleRefundDuplicateInvoiceDetails(row.data)}
          >
            <HiOutlineReceiptRefund size={14} /> Refund Duplicates
          </button>
        </center>
      ),
      headerName: 'Refund Duplicate Invoices'
    });

  const renderPdfInvoiceStatusClass = (status) => {
    if (status == 'Paid') {
      return {
        color: '#064e3b',
        backgroundColor: '#a7f3d0',
        padding: '2px',
        borderRadius: '20px',
        margin: '5px 0 5px 2px'
      };
    } else if (status == 'Processing') {
      return {
        color: '#1e3a8a',
        backgroundColor: '#bfdbfe',
        padding: '2px',
        borderRadius: '20px',
        margin: '5px 0 5px 2px'
      };
    } else if (status == 'Refunded') {
      return {
        color: '#111827',
        backgroundColor: '#e5e5e5',
        padding: '2px',
        borderRadius: '20px',
        margin: '5px 0 5px 2px'
      };
    } else {
      return {
        color: '#7f1d1d',
        backgroundColor: '#fecaca',
        padding: '2px',
        borderRadius: '20px',
        margin: '5px 0 5px 2px'
      };
    }
  };
  // Prepare styles for the PDF
  const styles = StyleSheet.create({
    page: {
      padding: 20,
      fontFamily: 'Helvetica'
    },
    title: {
      fontSize: 18,
      marginBottom: 10
    },
    description: {
      fontSize: 10,
      marginTop: 20,
      marginBottom: 20
    },
    table: {
      fontSize: 8,
      display: 'table',
      width: 'auto',
      borderStyle: 'solid',
      borderWidth: 2,
      borderColor: '#B0B0B0',
      borderRadius: 5,
      overflow: 'hidden'
    },
    tableRow: {
      display: 'table-row'
    },
    tableCell: {
      padding: 4,
      borderStyle: 'solid',
      flexGrow: 1,
      width: '100px',
      // borderWidth: 1,
      // borderColor: '#B0B0B0',
      textAlign: 'center',
      fontSize: 8
    },
    tableCellLarge: {
      padding: 4,
      borderStyle: 'solid',
      flexGrow: 2,
      width: '180px',
      // borderWidth: 1,
      // borderColor: '#B0B0B0',
      textAlign: 'center',
      fontSize: 8
    },
    tableHeader: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      backgroundColor: '#edf2f9',
      fontWeight: 'Bold',
      fontSize: 10
    },
    tableCellAlt: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      backgroundColor: '#f2f2f2'
    },
    tableCellEven: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      backgroundColor: '#ffffff'
    },
    logo: {
      marginBottom: 30,
      width: '40%',
      height: '6%',
      alignSelf: 'flex-start'
    }
  });

  // Create PDF document
  const MyDocument = () => (
    <Document>
      <Page style={styles.page}>
        <Image style={styles.logo} src={logo} />
        {/* Dynamic Content */}
        <Text style={styles.title}>INVOICE TABLE</Text>
        <View
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          <Text style={styles.description}>Patient ID : {customer.customer_id}</Text>
          <Text style={styles.description}>
            Patient Name : {`${customer.first_name} ${customer.last_name}`}
          </Text>
          <Text style={styles.description}>HSPP ID : {customer.hspp_id}</Text>
        </View>

        <View style={styles.table}>
          {/* Table Header */}
          <View style={[styles.tableRow, styles.tableHeader]}>
            <Text style={styles.tableCell}>Status</Text>
            <Text style={styles.tableCell}>Due Date</Text>
            <Text style={styles.tableCell}>Payment Method</Text>
            <Text style={styles.tableCell}>Amount</Text>
            <Text style={styles.tableCellLarge}>Transaction ID</Text>
            <Text style={styles.tableCell}>Payment Process Date</Text>
          </View>

          {/* Table Rows */}
          {customer.invoices.map((row, index) => (
            <View
              key={index}
              style={[
                styles.tableRow,
                index % 2 === 0 ? styles.tableCellEven : styles.tableCellAlt
              ]}
            >
              {/* {row.map((cell, idx) => (
                  <Text key={idx} style={styles.tableCell}>
                    {cell.Name}
                  </Text>
                )
                )} */}
              <Text
                key={index}
                style={[
                  styles.tableCell,
                  // { textTransform: uppercase }
                  renderPdfInvoiceStatusClass(row.status)
                ]}
              >
                {row.status.toUpperCase()}
              </Text>
              <Text key={index} style={styles.tableCell}>
                {moment(row.due_date).format('YYYY-MM-DD')}
              </Text>
              <View key={index} style={styles.tableCell}>
                {row.payment_gateway_transaction_id === 'credit' ? (
                  <Text>Credits</Text>
                ) : (
                  row.payment_details?.payment_method && (
                    <Text>
                      {row.payment_details?.payment_method == 'check'
                        ? 'Check : **' + row.payment_details?.ach_details?.account_last_4_digit
                        : 'Card : **' + row.payment_details?.card_details?.card_last_4_digit}
                    </Text>
                  )
                )}
              </View>
              <Text key={index} style={styles.tableCell}>
                ${row.amount}
              </Text>
              <Text key={index} style={styles.tableCellLarge}>
                {row.payment_gateway_transaction_id}
              </Text>
              <Text style={styles.tableCell}>
                {row.status === 'Refunded'
                  ? moment(row?.refunded_data[0]?.refunded_on).format('YYYY-MM-DD')
                  : row.paid_on && moment(row.paid_on).format('YYYY-MM-DD')}
              </Text>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );

  return (
    <Card className="rounded-xl w-full md:w-[68%]" paddingClass="p-3">
      <div className="w-full flex justify-between">
        <h3 className="text-xl font-bold pb-2">Invoice</h3>
        <PDFDownloadLink document={<MyDocument />} fileName="invoice_data.pdf">
          <button className="border-[1px] border-red-700 text-red-700 text-xs px-2 py-1 rounded-md flex gap-2 justify-center items-center">
            {!customer.invoices.length > 0 && <Spinner animation="border" size="sm" />}
            <GrNotes />
            Download PDF
          </button>
        </PDFDownloadLink>
      </div>
      {/* <div className="ag-theme-alpine" style={{ height: '70vh' }}> */}
      <AgGridTable
        className="h-[70vh] border-2 bg-slate-400"
        //   reference={_this.gridRef}
        rowData={customer.invoices}
        columnDefs={invoice_table_columns}
        masterDetail={true}
        fitToScreen={true}
        detailRowHeight={310}
        autoResizeColumn={true}
        stopEditingWhenCellsLoseFocus={true}
        border={true}
        pagination={true}
        paginationPageSize={10}
      />
      {/* </div> */}
    </Card>
  );
};

export default Invoice;
