import React from 'react';
import { Table } from 'components';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Card, Button, Input, Dropdown, DatePicker, Row, Checkbox } from 'components';
import { Modal, Form } from 'react-bootstrap';
import ButtonBTN from 'react-bootstrap/Button';
import * as FileServer from 'file-saver';
import * as XLSX from 'xlsx';

const Body = ({ _this }) => {
  const columns = [
    {
      fixed: 'left',
      headerStyle: { backgroundColor: '#474747', fontSize: '90%' },
      accessor: 'patient_id',
      style: { backgroundColor: '#C6C6C6' },
      Header: 'Patient ID',
      Cell: (row) => (
        <Link
          to={`/customer-info/${row.original.customer_mongo_id}`}
          className="text-blue-500 font-medium"
        >
          {row.original.patient_id}
        </Link>
      )
    },
    {
      accessor: 'patient_name',
      headerStyle: { fontSize: '90%' },
      Header: 'Patient Name',
      Cell: (row) => <div>{row.original.patient_name}</div>
    },
    {
      accessor: 'location',
      headerStyle: { fontSize: '90%' },
      Header: 'Location',
      Cell: (row) => <div>{row.original.location}</div>
    },
    {
      accessor: 'current_plan_name',
      headerStyle: { fontSize: '90%' },
      Header: 'Current Plan Name',
      minWidth: 200,
      Cell: (row) => <div>{row.original.current_plan_name}</div>
    },
    {
      accessor: 'old_plan_amount',
      headerStyle: { fontSize: '90%' },
      Header: 'Old Plan Amount',
      Cell: (row) => <div>{row.original.old_plan_amount}</div>
    },
    {
      accessor: 'expiry_date',
      headerStyle: { fontSize: '90%' },
      Header: 'Expiry Date',
      Cell: (row) => <div>{moment(row.original.expiry_date).format('YYYY-MM-DD')}</div>
    },
    {
      accessor: 'plan_interval',
      headerStyle: { fontSize: '90%' },
      Header: 'Plan Interval',
      Cell: (row) => <div>{row.original.plan_interval}</div>
    },
    {
      accessor: 'new_plan_name',
      headerStyle: { fontSize: '90%' },
      Header: 'New Plan Name',
      minWidth: 200,
      Cell: (row) => <div>{row.original.new_plan_name}</div>
    },
    {
      accessor: 'new_plan_amount',
      headerStyle: { fontSize: '90%' },
      Header: 'New Plan Amount',
      Cell: (row) => <div>{row.original.new_plan_amount}</div>
    },
    {
      accessor: 'plan_upgrade_date',
      headerStyle: { fontSize: '90%' },
      Header: 'Plan Upgrade Date',
      Cell: (row) => (
        <div>{moment(row.original.expiry_date).add(1, 'day').format('YYYY-MM-DD')}</div>
      )
    }
  ];
  return (
    <Table
      keyField="_id"
      columns={columns}
      data={_this.tableData}
      headerComponent={null}
      minRows={20}
    />
  );
};

export default Body;
