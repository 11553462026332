import React from 'react';
import { Table } from 'components';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Card, Button, Input, Dropdown, DatePicker, Row, Checkbox } from 'components';
import { Modal, Form } from 'react-bootstrap';
import ButtonBTN from 'react-bootstrap/Button';
import * as FileServer from 'file-saver';
import * as XLSX from 'xlsx';
import Upgrade from './Upgrade';
import Renewal from './Renewal';

const Body = ({ _this }) => {
  const exportReport = () => {
    const extractedDataArray = [];
    for (const dataObject of _this.tableData) {
      let extractedData;

      if (_this.searchBy.value == 'upgrade') {
        extractedData = {
          'Patient ID': dataObject.patient_id,
          'Patient Name': dataObject.patient_name,
          Location: dataObject.location,
          'Current Plan Name': dataObject.current_plan_name,
          'Current Plan Amount': dataObject.old_plan_amount,
          'Expiry Date': moment(dataObject.expiry_date).format('YYYY-MM-DD'),
          'Plan Interval': dataObject.plan_interval,
          'New Plan Name': dataObject.new_plan_name,
          'New Plan Amount': dataObject.new_plan_amount,
          'Plan Upgrade Date': moment(dataObject.plan_upgrade_date)
            .add(1, 'days')
            .format('YYYY-MM-DD')
        };
      } else {
        extractedData = {
          'Patient ID': dataObject.patient_id,
          'Patient Name': dataObject.patient_name,
          Location: dataObject.location,
          'Current Plan Name': dataObject.current_plan_name,
          'Current Plan Amount': dataObject.old_plan_amount,
          'Expiry Date': moment(dataObject.expiry_date).format('YYYY-MM-DD'),
          'Plan Interval': dataObject.plan_interval,
          'Plan Renewal Date': moment(dataObject.plan_renewal_date).format('YYYY-MM-DD')
        };
      }

      extractedDataArray.push(extractedData);
    }

    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const ws = XLSX.utils.json_to_sheet(extractedDataArray);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'data');

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    // Create a Blob and save the Excel file
    const data = new Blob([excelBuffer], { type: fileType });
    FileServer.saveAs(data, 'SubscriptionPlanChangeReport' + fileExtension);
  };

  return (
    <Card className="flex mr-2 mt-2" paddingClass="px-1 py-2">
      <div className="flex justify-between items-center p-2">
        <div className="flex gap-2">
          <ButtonBTN
            onClick={() => exportReport()}
            className={`bg-button-primary  mb-2`}
            variant="primary"
            id="export"
          >
            Export
          </ButtonBTN>
        </div>
        <h3 className="text-body-color text-xl font-semibold">
          Subscription Plan Change Report({_this.tableData?.length})
        </h3>

        <Dropdown
          label="Search By"
          className="w-48 z-10"
          placeholder="@report"
          required
          type="text"
          options={_this.options}
          aria-label="Default select example"
          value={_this.searchBy}
          onChange={(e) => {
            _this.setSearchBy(e);
          }}
        />

        <input
          type="search"
          placeholder="Search.."
          value={_this.searchInput}
          onChange={(e) => _this.setSearchInput(e.target.value)}
        />
      </div>

      {_this.searchBy.value == 'upgrade' ? <Upgrade _this={_this} /> : <Renewal _this={_this} />}
    </Card>
  );
};

export default Body;
